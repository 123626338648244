<template>
  <div lesson-order>
    <div class="top">
      <hollow-badge :type="badgeType[0]">{{ $t(badgeType[1]) }}</hollow-badge>
      <h1 class="lesson-title">{{ title }}</h1>
      <div class="order-date">
        <p v-show="startTime" class="date-start">{{ $t('coaching.MC_MAIN_ECUCATION_START_TIME', { startDate: formattedStartTime }) }}</p>
        <p v-show="endTime" class="date-end">{{ $t('coaching.MC_MAIN_ECUCATION_END_TIME', { endDate: formattedEndTime }) }}</p>
      </div>
    </div>
    <hr />
    <div class="bottom">
      <template v-if="isCoach">
        <avatar :info="ownerInfo" :defaultImgType="'fruit'" dimension="48x48" userProfile />
        <p class="student-name">{{ ownerName }}</p>
      </template>
      <template v-else>
        <avatar :info="tutorInfo" :defaultImgType="'fruit'" dimension="48x48" @click="onTutorClick" class="clickable" userProfile />
        <p @click="onTutorClick" class="tutor-name">{{ tutorName }}</p>
      </template>
      <div class="right-corner">
        <client-only>
          <template v-if="status === 'WAIT'">
            <color-button v-if="!isCoach" @click="cancelBtnHandler">{{ $t('coaching.BA_MAIN_ECUCATION_REQUEST_CANCEL') }}</color-button>
          </template>
          <template v-if="status === 'END' && !hasReview">
            <color-button v-if="!isCoach" @click="reviewBtnHandler">{{ $t('coaching.BA_main_education_writereivew') }}</color-button>
          </template>
        </client-only>
        <template v-if="(status === 'END' || status === 'SETTLED') && hasReview">
          <div class="badge-review">리뷰 완료</div>
          <rating-star :star-size="10" :value="reviewRating" readonly />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import Avatar from '@shared/components/common/Avatar.vue';
import HollowBadge from '@/views/components/coaching/HollowBadge.vue';
import RatingStar from '@/views/components/general/RatingStar.vue';
import coach from '@/mixins/coach';

export default {
  components: { RatingStar, Avatar, ColorButton, HollowBadge },
  mixins: [coach],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isCoach: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    v() {
      return /** @type {LessonOrder} */ this.value;
    },
    title() {
      return this.v?.lesson?.name || '';
    },
    status() {
      return this.v?.status || 'WAITING';
    },
    endTime() {
      return this.v?.endDateTime || 0;
    },
    startTime() {
      return this.v?.startDateTime || 0;
    },
    formattedStartTime() {
      return this.$date(this.startTime, 'datetimeAlt');
    },
    formattedEndTime() {
      return this.$date(this.endTime, 'datetimeAlt');
    },
    tutorProfileUrl() {
      return this.v?.coach?.user?.profileImage || '';
    },
    tutorName() {
      return this.v?.coach?.user?.nickname || '';
    },
    tutorUserId() {
      return this.v?.coach?.user?.userId || '';
    },
    tutorInfo() {
      return this.v?.coach?.user || {};
    },
    reviewRating() {
      return this.v?.review?.rating || 0;
    },
    hasReview() {
      return this.v?.review?.lessonReviewId || '';
    },
    lessonProductOrderId() {
      return this.v?.lessonProductOrderId || '';
    },
    ownerInfo() {
      return /** @type {LvupUser} */ this.v?.owner;
    },
    ownerName() {
      return this.ownerInfo?.nickname || '';
    },
    badgeType() {
      /** @type {Object<LessonStatus, [string, string]>} */
      const badgeTypes = {
        WAIT: ['orange', 'coaching.MC_MAIN_EDUCATION_TAG_REQUEST'],
        ONGOING: ['deep-green', 'coaching.MC_MAIN_EDUCATION_TAG_INCLASS'],
        END: ['deep-blue', 'coaching.MC_MAIN_EDUCATION_TAG_FINISHED'],
        SETTLED: ['deep-blue', 'coaching.MC_MAIN_EDUCATION_TAG_FINISHED'],
        /** 정책 상 수정, 'SETTLED' 상태에서도 'END' 상태와 동일하게 Display 되도록.  */
        // SETTLED: ['black', 'coaching.MC_MAIN_EDUCATION_TAG_CONFIRMED'],
        REFUNDED: ['deep-grey', 'coaching.MC_MAIN_EDUCATION_TAG_CANCELED'],
        CANCEL: ['deep-grey', 'coaching.MC_MAIN_EDUCATION_TAG_CANCELED'],
      };
      return badgeTypes[this.status] || ['deep-grey', 'UNKNOWN'];
    },
  },
  methods: {
    reviewBtnHandler() {
      this.$emit('review', { info: this.value });
    },
    cancelBtnHandler() {
      this.$emit('cancel', { lessonProductOrderId: this.lessonProductOrderId });
    },
    onTutorClick() {
      this.openTutor(this.v.coach.coachId);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[lesson-order] {
  .wh(100%, 215);.bgc(#fff);.br(20);.rel;.p(0, 20, 0, 20);

  [avatar] { .wh(48);.br(40);.mr(10);}
  [avatar].clickable {cursor: pointer;}
  .tutor-name { cursor: pointer; .max-w(170); .ellipsis;}
  .student-name { .max-w(170); .ellipsis;}

  > .top {
    [hollow-badge] {.abs;.rt(20, 27);}

    > h1 {.fs(20, 28px);.bold;.c(#000);.abs;.lt(20, 23);.noto;}

    .order-date {
      > * {.c(#787781);.fs(14, 16px);.abs;.noto;}

      .date-start { .lt(20, 60)}

      .date-end {.lt(20, 80)}
    }
  }

  > .bottom {
    .flex;.pt(150);.flex-ai(center);.noto;

    .badge-review {.bgc(#000);.c(#fff);.br(12);.pr(8);.pl(8);.flex-center;.h(22)}

    .right-corner {.abs;.rb(25, 15);.flex;.flex-column; .items-center; .flex-jc(center);}
  }

  > hr {.abs;.t(130);.w(calc(100% - 40px));.mh-c;border-top: solid 1px #dce0e3;}
}
</style>
