<template>
  <div hollow-badge :class="type">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'blue',
      validator(badgeType) {
        return ['red', 'yellow', 'green', 'blue', 'purple', 'grey', 'white', 'orange', 'black', 'deep-green', 'deep-blue', 'deep-grey'].includes(badgeType);
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[hollow-badge] {
  .chakra;.ib;.h(22);.lh(19);.br(12);.fs(13);.pl(8);.pr(8);.bgc(@c-w004);
  font-weight: 800;border: solid 2px;

  &.white {.c(#fff);border-color: #fff;}

  &.green {color: #12d5a3;border-color: #12d5a3;}

  &.blue {color: #2c81ff;border-color: #2c81ff;}

  &.purple {color: #8300ff;border-color: #8300ff;}

  &.grey {color: #a4a3ae;border-color: #a4a3ae;}

  &.red {color: #df3a3a;border-color: #df3a3a;}

  &.orange {color: #ea9135;border-color: #ea9135;}

  &.black {color: #000;border-color: #000;}

  &.deep-green {color: #528b46;border-color: #528b46;}

  &.deep-blue {color: #2c81ff;border-color: #2c81ff;}

  &.deep-grey {color: #787781;border-color: #787781;}
}
</style>
