<template>
  <Modal coach-lesson-cancel-modal @close="onClose">
    <template v-slot:title>
      <h2 class="title">{{ $t('coaching.PT_REQUEST_CANCEL') }}</h2>
    </template>
    <template>
      <div class="inner-view">
        <div class="content">
          <div class="reason-wrap">
            <h3 class="reason-text">{{ $t('coaching.PC_REQUEST_CANCEL_REASON_SELECT') }}</h3>
            <div class="reason-select-list">
              <em class="selected-item item" @click="showItems = !showItems">{{ selectedItemText }}</em>
              <div v-show="showItems" class="item-wrap">
                <ul v-for="(item, i) in selectItems" :key="i" class="reason-select-item item" @click="selectItem(item)">
                  <li>
                    {{ $t(`coaching.PC_REQUEST_CANCEL_REASON_SELECT_LIST_${i + 1}`) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ValidationObserver ref="validator" class="text-box-wrap" tag="div">
            <ValidationProvider v-slot="{errors}" name="cancelCause" rules="required|length:10,200">
              <TextBox v-if="selectedEtc" v-model="detailValue" :placeholder="$t('coaching.IG_REQUEST_CANCEL_REASON_TEXT')" class="text-box"></TextBox>
              <ValidationErrors :errors="errors" />
            </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="control">
          <ColorButton :disabled="!selectedItem" @click="cancel">
            <span>{{ $t('coaching.BA_REQUEST_CANCEL_CONFIRM') }}</span>
          </ColorButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import TextBox from '@/views/components/common/input/TextBox.vue';

export default {
  name: 'CoachLessonCancelModal.vue',
  components: { ColorButton, Modal, TextBox },
  props: {
    options: {},
  },
  data: () => ({
    detailValue: '',
    selectedItem: '',
    showItems: false,
  }),
  computed: {
    selectItems() {
      return ['CHANGE_OF_MIND', 'WRONG_SELECTION', 'DELAYED_ANSWER', 'ETC'];
    },
    selectedItemText() {
      if (!this.selectedItem) return this.$t('coaching.IG_REQUEST_CANCEL_REASON_SELECT');
      const idx = this.selectItems.indexOf(this.selectedItem);
      return this.$t(`coaching.PC_REQUEST_CANCEL_REASON_SELECT_LIST_${idx + 1}`);
    },
    selectedEtc() {
      return this.selectedItem === 'ETC';
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    selectItem(item) {
      this.selectedItem = item;
      this.showItems = false;
    },
    async cancel() {
      if (this.selectedEtc) await this.$validate(this.$refs.validator);
      const lessonProductOrderId = this.options?.lessonProductOrderId;
      const detail = this.detailValue;
      await this.$services.coaching.postLessonCancel({ lessonProductOrderId, cancelReason: this.selectedItem, detail });
      this.$emit('resolve');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-lesson-cancel-modal] {
  .panel {.max-w(335); }
  .panel > .large {.max-w(335); }
  .panel.large > .scroll-area {.p(20); .h(388);}
  .panel > .scroll-area {.p(20); .h(388); }

  .title {.fs(16); .c(#191919); }
  .inner-view { .mt(30);
    .content {
      .reason-wrap {.flex; .w(100%); .h(40);
        .reason-text {.fs(14); .bold; .c(#191919); .nowrap; .pt(8);}
        .reason-select-list { .w(100%); .ml(10); .rel; .bgc(#fff); .z(1);
          .item {.nowrap; .h(40); .pl(12); cursor: pointer; }
          .selected-item {.abs; .t(0); .l(0); .flex; .items-center; .h(40); .w(100%); border: solid 1px #d5d5de; .br(12); .z(1); .bgc(#fff); }
          .item-wrap { .abs; .w(100%); .pt(30); .pb(10); .t(20); .l(0); border: solid 1px #d5d5de; .br(12); .bgc(#fff);
            .reason-select-item {.flex; .items-center;
              &:hover {.bgc(rgba(0, 0, 0, 0.1));}
            }
          }
        }
      }
      .text-box-wrap { .rel;
        .text-box {.mt(20); .w(100%); .h(164); position: inherit;
          > textarea {.fs(14);}
        }
        [validation-errors] {.h(12); .mt(4); .b(-16); .r(0); .abs;}
        .error {text-align: right; .c(#ffc70e); .fs(12); .lh(12); }
      }
    }
    .control {.abs; .b(30); transform: translateX(-50%); .l(50%);
      [color-button] {.w(200);}
    }
  }
}
</style>
