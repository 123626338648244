<template>
  <div area-more @click="$emit('click')">
    <label>
      <span>더보기</span>
      <button>
        <img src="/img/icon/coaching/ico_more_button.svg" />
      </button>
    </label>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import '~@/less/proj.less';

[area-more] {.w(100%);.noto;.flex;.flex-ai(center);.flex-jc(center);
  label {.flex;.flex-ai(center);.flex-jc(center);}
  label > span { .mr(8);.c(@c-text-gray);}
  button {.bgc(#d5d5de);.br(8);.wh(40);}
  &:hover button { .bgc(#b8b8c8); }
}
</style>
