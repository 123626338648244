<template>
  <!-- My 강의실 -->
  <coach-my-page-content-layout user-classes>
    <my-page-title>
      <template #left>
        {{ $t('_.myPage.navigation.classroom') }}
      </template>
      <template #right>
        <selector v-model="lessonStatus" :list="lessonStatusList" theme="white" />
      </template>
    </my-page-title>
    <div class="lesson-card-container">
      <lesson-order v-for="lessonOrder in lessonOrders"
        :key="lessonOrder.lessonProductOrderId"
        :isCoach="isCoach"
        :value="lessonOrder"
        @review="review"
        @cancel="cancel" />
    </div>
    <client-only>
      <div class="no-content" v-show="!hasLessonOrder">
        <template v-if="isCoach">
          <p>아직 수업 이력이 없습니다.</p>
        </template>
        <template v-else>
          <p>아직 수강 이력이 없습니다<br />첫 수업을 시작해볼까요?</p>
          <color-button type="dark" @click="onCoachFind" class="btn-find-class">내게 딱 맞는 수업 찾기</color-button>
        </template>
      </div>
    </client-only>
    <area-more v-show="hasNext" @click="onNext" />
  </coach-my-page-content-layout>
</template>

<script>
import '@/utils/polyfillUtils';

import ColorButton from '@shared/components/common/ColorButton.vue';
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout.vue';
import CoachLessonReviewModal from '@/views/components/coaching/CoachLessonReviewModal.vue';
import CoachLessonCancelModal from '@/views/components/coaching/CoachLessonCancelModal.vue';
import MyPageTitle from '@/views/components/coaching/coach-my-page/MyPageTitle.vue';
import LessonOrder from '@/views/components/coaching/user/LessonOrder.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import Selector from '@/views/components/coaching/Selector.vue';

export default {
  name: 'CoachUserClasses',
  lexicon: 'coaching',
  components: {
    Selector,
    AreaMore,
    LessonOrder,
    MyPageTitle,
    CoachMyPageContentLayout,
    ColorButton,
  },
  metaInfo() {
    return {
      title: this.$t('meta.myClass.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachLoL.title') },
        { vmid: 'description', content: this.$t('meta.coachLoL.description') },
        { vmid: 'keywords', content: this.$t('meta.coachLoL.keywords') },
        { vmid: 'url', content: this.$t('meta.coachLoL.url') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  data: () => ({
    now: 0,
    isEnd: false,
    isBeginning: true,
    /** @type{PagedLessonOrders || {}} */
    lessonOrderInfo: {},
    lessonStatusCounts: {},
  }),
  computed: {
    /** @type{MyInfo} */
    myInfo() {
      return this?.$store?.state?.auth?.myInfo || {};
    },
    isCoach() {
      return this.myInfo?.isCoach || false;
    },
    userId() {
      return this.myInfo?.id || '';
    },
    isUser() {
      return this.userId !== '';
    },
    lessonOrders() {
      return /** @type{LessonOrder[]} */ this.lessonOrderInfo?.items || [];
    },
    hasLessonOrder() {
      return !!this.lessonOrders.length;
    },
    hasNext() {
      // if (this.lessonOrderInfo?.totalCount > 0 && !this.isEnd) return true;
      return this.lessonOrderInfo?.hasNext || false;
    },
    query() {
      const baseQuery = {};
      if (this.lessonStatus === 'WAIT') baseQuery.status = 'WAIT';
      if (this.lessonStatus === 'CANCEL') baseQuery.q = 'status eq CANCEL or status eq REFUNDED';
      if (this.lessonStatus === 'END') baseQuery.q = 'status eq END or status eq SETTLED';
      if (this.userId === '') return baseQuery;
      if (this.isCoach) return { ...baseQuery, coachUserId: this.userId };
      return { ...baseQuery, userId: this.userId };
    },
    lessonOrderCount() {
      return this.lessonOrderInfo?.totalCount ?? 0;
    },
    lessonStatus: {
      get() {
        return this.$route?.query?.['lesson-status'] ?? '';
      },
      set(lessonStatus) {
        const query = this.getMergedQuery({ 'lesson-status': lessonStatus });
        this.$router.replace({ query });
      },
    },
    /**
     * @typedef {{value: import('services/coaching/src/types/Lesson.d.ts').LessonStatus, label: string}} LessonStatusListItem
     * @returns {LessonStatusListItem[]}
     * */
    lessonStatusList() {
      return [
        { value: '', label: `전체(${this.lessonStatusCounts.NONE})` },
        { value: 'WAIT', label: `대기중(${this.lessonStatusCounts.WAIT})` },
        { value: 'CANCEL', label: `취소(${this.lessonStatusCounts.CANCEL})` },
        { value: 'END', label: `종료(${this.lessonStatusCounts.END})` },
      ];
    },
  },
  watch: {
    lessonStatus: 'initLessonOrders',
  },
  methods: {
    async onNext() {
      if (!this.isEnd && this.hasNext) await this.fetchMore();
    },
    async fetchMore() {
      try {
        const lessonOrderInfo = await this.$services.coaching.getLessonOrders({
          ...this.query,
          cursor: this.lessonOrderInfo.nextCursor,
          size: 6,
          order: 'buyDateTime DESC',
        });
        this.lessonOrderInfo = {
          ...lessonOrderInfo,
          items: [...this.lessonOrderInfo.items, ...lessonOrderInfo?.items || []],
        };
      } catch (err) {
        console.error(err);
      }
    },
    async review(info) {
      await this.$modal(CoachLessonReviewModal, info);
      await this.initLessonOrders();
    },
    async cancel(arg) {
      await this.$modal(CoachLessonCancelModal, arg);
      await this.initLessonOrders();
    },
    async initLessonOrders() {
      this.lessonOrderInfo = await this.$services.coaching.getLessonOrders({ ...this.query, cursor: 0, size: 6, order: 'buyDateTime DESC' });
    },
    async lessonProductOrderStatusChangeHandler() {
      await this.initLessonOrders();
    },
    onCoachFind() {
      this.$router.push({ name: 'CoachMatching' });
    },
  },
  async mounted() {
    const defaultInfo = /** @type{PagedLessonOrders} */await this.$services.coaching.getLessonOrders({ ...this.query, size: 1 });
    const waitInfo = /** @type{PagedLessonOrders} */ (await this.$services.coaching.getLessonOrders({ ...this.query, status: 'WAIT', size: 1 }));
    const cancelInfo = /** @type{PagedLessonOrders} */ await this.$services.coaching.getLessonOrders({ ...this.query, q: 'status eq CANCEL or status eq REFUNDED', size: 1 });
    const endInfo = /** @type{PagedLessonOrders} */ await this.$services.coaching.getLessonOrders({ ...this.query, q: 'status eq END or status eq SETTLED', size: 1 });

    this.lessonStatusCounts = {
      NONE: defaultInfo.totalCount,
      WAIT: waitInfo.totalCount,
      CANCEL: cancelInfo.totalCount,
      END: endInfo.totalCount,
    };
    if (!this.isUser) return;
    /** 추후 다른 컴포넌트에서도 해당 event를 수신하는 등 수정이 필요하면, service에서 바인딩 하는 이벤트 list를 만들고, 해당 리스트의 callback 기능들을 수행하는 방식으로 수정할 것. */
    const privateChannel = `private-${this.$store.state.auth.userId}`;
    this.$bindPushEvent(privateChannel, 'lesson_product_order_started', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_ended', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_ordered', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_refunded', this.lessonProductOrderStatusChangeHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_canceled', this.lessonProductOrderStatusChangeHandler);
    await this.initLessonOrders();
  },
  beforeDestroy() {
    const privateChannel = `private-${this.$store.state.auth.userId}`;
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_started', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_ended', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_ordered', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_refunded', this.lessonProductOrderStatusChangeHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_canceled', this.lessonProductOrderStatusChangeHandler);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[user-classes] {
  .lesson-card-container {.flex;justify-content: flex-start;flex-wrap: wrap;.max-w(912);.m(auto);.mt(50)}
  [lesson-order] {.responsive-w(446);border-radius: 20px;box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    // 3번째 카드부터 모두 동일한 gap
    &:nth-of-type(n+3) {.mt(20);}
    // 우측 카드 모두 동일한 gap
    &:nth-of-type(2n) {.ml(20)}
  }
  [area-more] {.mt(50)}
  [selector] {z-index: 5;}
  [selector] ul {border-bottom-right-radius: 5px; border-bottom-left-radius: 5px;}
  .no-content {.flex;align-items: center;justify-content: center;flex-direction: column;
    > p {text-align: center;}
    [color-button] {.wh(260, 60);.mt(40)}
  }
  @media (@mypage-mobile-down) {
    [lesson-order] {
      &:nth-of-type(2) {.mt(20)}
      &:nth-of-type(2n) {.ml(0)}
      &:nth-of-type(n+1) {.ml(auto);.mr(auto);}
    }
  }
}
</style>
